import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../AppContext';
import { useLocation } from 'react-router-dom';
import { NavbarContext } from '../../Contexts';

function HideOnScroll() {
    const { state: { categories, showBottomNav }, state, update } = useContext(AppContext);
    const { navbarState, navbarUpdate } = useContext(NavbarContext)
    const location = useLocation();


    const [prevScrollPosition, setPrevScrollPosition] = useState(window.scrollY);
    const [updatingContext, setUpdatingContext] = useState(false);

    const checkBottomPage = (offset) => {
        const offsetHeight = document.documentElement.offsetHeight;
        const innerHeight = window.innerHeight;
        const scrollTop = document.documentElement.scrollTop;

        const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= (offset || 55);

        return hasReachedBottom
    }

    useEffect(() => {
        if (navbarState?.blockNavHide) return

        let scrollStartAction = null
        let scrollEndAction = null;
        let localUpdatingContext = updatingContext;

        const detectScroll = () => {
            clearTimeout(scrollEndAction)
            let currentScrollPosition = window.scrollY

            if (currentScrollPosition > prevScrollPosition && currentScrollPosition > 48 && !checkBottomPage(55)) {
                // document.getElementsByClassName('navbar')?.[0].classList.replace('show', "hide")
                // document.getElementsByClassName('mobile-nav')?.[0].classList.replace('show', "hide")
                if (!navbarState?.navbarHide && !localUpdatingContext) {
                    setUpdatingContext(true)
                    localUpdatingContext = true
                    navbarUpdate({
                        ...navbarState,
                        navbarHide: true,
                        bottomNavHide: true
                    })
                }
            }

            else if (checkBottomPage(0)) {
                // document.getElementsByClassName('navbar')?.[0].classList.replace('hide', "show")
                // document.getElementsByClassName('mobile-nav')?.[0].classList.replace('hide', "show")

                if (navbarState?.bottomNavHide && !localUpdatingContext) {
                    setUpdatingContext(true)
                    localUpdatingContext = true
                    navbarUpdate({
                        ...navbarState,
                        bottomNavHide: false,
                        navbarHide: false
                    })
                }
            }

            else if (currentScrollPosition <= 48 || currentScrollPosition <= prevScrollPosition) {
                // document.getElementsByClassName('navbar')?.[0].classList.replace('hide', "show")
                // document.getElementsByClassName('mobile-nav')?.[0].classList.replace('hide', "show")
                if (navbarState?.navbarHide && !localUpdatingContext) {
                    setUpdatingContext(true)
                    localUpdatingContext = true
                    navbarUpdate({
                        ...navbarState,
                        navbarHide: false,
                        bottomNavHide: false
                    })
                }
            }


            scrollStartAction = setTimeout(() => {
                setPrevScrollPosition(window.scrollY)
            }, 250);
        }

        const detectScrollEnd = () => {
            clearTimeout(scrollStartAction)
            setPrevScrollPosition(window.scrollY)

            // Add delay to show navbar on user stop scrolling
            // scrollEndAction = setTimeout(() => {
            if (checkBottomPage(55)) {
                // document.getElementsByClassName('navbar')?.[0].classList.replace('hide', "show")
                // document.getElementsByClassName('mobile-nav')?.[0].classList.replace('hide', "show")

                if (navbarState?.bottomNavHide && !localUpdatingContext) {
                    setUpdatingContext(true)
                    localUpdatingContext = true
                    navbarUpdate({
                        ...navbarState,
                        bottomNavHide: false,
                        navbarHide: false
                    })
                }
            }
            // }, 1000);
        }

        document.addEventListener("scroll", detectScroll)
        document.addEventListener("scrollend", detectScrollEnd)

        return () => {
            document.removeEventListener("scroll", detectScroll)
            document.removeEventListener("scrollend", detectScrollEnd)
        }
    }, [prevScrollPosition, navbarState?.blockNavHide])

    useEffect(() => {
        if (categories) {

            navbarUpdate({
                ...navbarState,
                navbarHide: false,
                bottomNavHide: false,
                blockNavHide: false,
            })

            update({
                ...state,
                showBottomNav: true
            })
        }
    }, [location])

    useEffect(() => {
        if (showBottomNav) {
            if (Array.from(document.body.classList)?.length > 1) {
                document.body.classList.remove('pb-0')
            } else {
                document.body.removeAttribute('class')
            }
        } else {
            document.body.classList.add('pb-0')
        }
    }, [showBottomNav])

    useEffect(() => {
        if (updatingContext) {
            setUpdatingContext(false)
        }
    }, [updatingContext])

    return null
}

export default HideOnScroll