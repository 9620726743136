import React, { createContext, useReducer } from 'react'

export const AppContext = createContext();

const reducer = (state, pair) => ({ ...state, ...pair })

const initialState = {
	currentLanguage: window.location.pathname.split("/")[1] || "es",
	collapsed: false,
	languages: [],
	categories: [],
	detailSlugs: [],
	user: {},
	mobileViewport: null,
	advancedMap: false,
	showBottomNav: true,
	returnRedirect: null
}

export function AppProvider({languages, user, children}) {
	
	initialState.languages = languages
	initialState.user = user

	const [state, update] = useReducer(reducer, initialState)

	return (
		<AppContext.Provider value={{ state, update }}>
			{children}
		</AppContext.Provider>
	)
}
