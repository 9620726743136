import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStickyBox } from 'react-sticky-box';
import { FreeMode, Navigation } from 'swiper/modules';
import MiniGuideService from '../../services/miniGuide.service';
import NewContentCardPlaceholder from '../../components/cards/NewContentCardPlaceholder';
import NewContentCard from '../../components/cards/NewContentCard';
import Link from '../../components/Link';
import SubscribeModal from '../../components/modals/SubscribeModal';
import { FavouritesContext, NavbarContext } from '../../Contexts';
import { useNavigate } from "react-router-dom";
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';

function NewMiniGuide() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { state: { categories, banners, user }, state, update } = useContext(AppContext)
    const { navbarState: { navbarHide }, navbarUpdate } = useContext(NavbarContext)
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const [miniGuides, setMiniGuides] = useState();
    const [totalMiniGuides, setTotalMiniGuides] = useState()
    const [offset, setOffset] = useState(0);
    const [HasMore, setHasMore] = useState(false)

    const [filterList, setFilterList] = useState([])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const cancelRequest = useRef()

    const [mobileViewport, setMobileViewport] = useState(window.innerWidth <= 425)
    const stickyRef = useStickyBox({ offsetTop: !mobileViewport ? 59 : navbarHide ? 0 : 55 })

    const handleFilter = (type) => {
        const localFilterList = [...filterList];
        const filterIndex = localFilterList.indexOf(type);

        if (filterIndex >= 0) {
            localFilterList.splice(filterIndex, 1)
            setFilterList(localFilterList)
        } else {
            setFilterList([
                ...localFilterList,
                type
            ])
        }
    }

    const getMiniGuides = async () => {
        try {
            const data = await MiniGuideService.getMiniGuide({ length: 6, categoryIds: filterList?.toString(), offset: offset })

            setMiniGuides(data)

            if (miniGuides?.length > 0) {
                setMiniGuides([
                    ...miniGuides,
                    ...data
                ])
            } else {
                setMiniGuides(data)
            }

            setOffset(offset + 1)
            if (data?.length === 6) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (miniGuides) {
            cancelRequest.current = setTimeout(() => {
                setOffset(0)
            }, 1000);
        }

        return () => {
            clearTimeout(cancelRequest.current)
        }
    }, [filterList])

    useEffect(() => {
        if (filterList?.length > 0 && !miniGuides) {
            getMiniGuides()
        } else if (filterList?.length === 0 && !miniGuides) {
            getMiniGuides()
        }
    }, [miniGuides])

    useEffect(() => {
        if (offset === 0 && miniGuides) {
            setMiniGuides()
        }
    }, [offset])

	useEffect(() => {
        let waitResize;		
		
        const checkResize = () => {
			setMobileViewport(window.innerWidth <= 425)
        }

        window.addEventListener('resize', () => {			
            clearTimeout(waitResize);
            waitResize = setTimeout(checkResize, 100)
        })

        return () => window.removeEventListener('resize', checkResize)
	}, [])


    return (
        <>
            <SeoHelmet title={t('seo.miniGuide.title')} metaTitle={t('seo.miniGuide.metaTitle')} metaDescription={t('seo.miniGuide.metaDescription')} ogImage={'/img/home-image.jpg'} />
            <main>
                <section>
                    <Row className='guide-hero g-0 flex-column-reverse flex-lg-row'>
                        <Col lg={4} className='d-flex flex-column justify-content-center align-items offset-lg-1 p-4 p-lg-0'>
                            <div className='d-flex flex-column justify-content-center gap-3 gap-lg-4 mb-4 mb-lg-0'>
                                <h1 className='fs-title fitzgerald-regular text-dark-green mb-0'>It's-a-must</h1>
                                <p className='inter-medium text-uppercase letter-spacing-1 fs-5 mb-0 text-dark-green'>{t('miniGuide.head.subtitle')}</p>
                                <p className='inter-regular fs-6 fs-lg-5 text-dark-green'>{t('miniGuide.head.description')}</p>
                            </div>
                        </Col>
                        <Col lg={6} className='offset-lg-1'>
                            <Image src="/img/mini-guides/hero-must.webp" className='fit-cover' />
                        </Col>
                    </Row>
                </section>
                <section className='bg-laurel guide-category-filter' ref={stickyRef}>
                    <div className='px-0 px-lg-4 container-lg'>
                        <Swiper
                            navigation={true}
                            breakpoints={{
                                768: {
                                    slidesOffsetBefore: 24,
                                    slidesOffsetAfter: 24
                                }
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={32}
                            slidesOffsetBefore={window.innerWidth < 768 ? 24 : 0}
                            slidesOffsetAfter={window.innerWidth < 768 ? 24 : 0}
                            className='w-100 py-3'
                            shortSwipes
                            longSwipes
                            freeMode
                            modules={[FreeMode, Navigation]}
                        >
                            {categories.map((cat, i) =>
                                <SwiperSlide>
                                    <Form.Check
                                        key={i}
                                        id={cat?.slugs?.[i18n?.language] || cat?.name}
                                        className='tt-norms d-flex flex-row align-items-center filter-item justify-content-center mb-0 inter-medium text-white letter-spacing-1'
                                        label={cat.name}
                                        onChange={() => handleFilter(cat?.id)}
                                    />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </section>
                {(!miniGuides || miniGuides?.length > 0) && <section className='bg-beige py-5 border-bottom border-coal-semi-transparent'>
                    <div className='d-flex flex-column container-lg '>
                        <h2 className='inter-semi-bold fs-2 text-brown letter-spacing-2 text-uppercase mb-3'>It's-a-must</h2>
                        <h3 className='inter-regular fs-4 text-dark-green mb-4 mb-lg-5 text-brown'>{t('miniGuide.topSelection')}</h3>
                        <Row className='gy-5 gx-4 mb-5'>
                            {!miniGuides && (
                                [1, 2, 3].map(() => (
                                    <Col lg={4}>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                ))
                            )}
                            {miniGuides?.map((miniGuide, i) => (
                                <Col lg={4} key={i}>
                                    <NewContentCard theme={"brown"} image={miniGuide?.mainPicture} category={miniGuide?.category} title={`${miniGuide?.title}`.trim()} slug={`${t('pages.miniGuide')}/${miniGuide?.categorySlug}/${miniGuide?.slug?.[i18n.language]}`} id={miniGuide?.id} type={"MINIGUIDE"} />
                                </Col>
                            ))}
                        </Row>
                        {HasMore && <Button className='btn-brown-outline letter-spacing-1 inter-regular py-3 px-5 align-self-center' onClick={() => getMiniGuides()}>{t('general.components.seeAllM')}</Button>}
                    </div>
                </section>}
                <aside className='bg-laurel py-5 py-lg-3 mb-4'>
                    <div className='container-lg d-flex flex-column flex-lg-row justify-content-between align-items-center'>
                        <span className='fs-lg-2 fs-title fitzgerald-regular text-white lh-sm mb-2 mb-lg-0'>{t('miniGuide.registerBanner.title')}</span>
                        <div className='d-flex flex-column flex-lg-row gap-3 align-items-center'>
                            <span className='inter-regular text-white fs-6 fs-lg-5'>{t('miniGuide.registerBanner.text')}</span>
                            <Button variant='none' className='btn-beige-outline text-uppercase inter-regular letter-spacing-1 py-2 px-4' onClick={handleShow}>{t('miniGuide.registerBanner.button')}</Button>
                        </div>
                    </div>
                </aside>
                <aside className='container-lg my-4'>
                    <Link href={t('pages.promos')} noRedirect>
                        <div onClick={() => {
                            user ? navigate(`/${i18n.language}/${t('pages.profile')}/${t('pages.favourites')}`) : favouriteUpdate({ ...favouriteState, favoriteModal: true })
                        }}>
                            <video autoPlay loop muted playsInline className="promotion-banner d-block d-lg-none">
                                <source autoPlay src={banners?.mobile} type={"video/mp4"} />
                            </video>
                            <video autoPlay loop muted playsInline className="promotion-banner d-none d-lg-block">
                                <source autoPlay src={banners?.desktop} type={"video/mp4"} />
                            </video>
                        </div>
                    </Link>
                </aside>
                <SubscribeModal show={show} close={handleClose} />
            </main>
        </>
    )
}

export default NewMiniGuide