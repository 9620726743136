import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Image, Row } from 'react-bootstrap'
import { useStickyBox } from 'react-sticky-box'
import { FreeMode, Navigation } from 'swiper/modules'
import { AppContext } from '../../AppContext'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'
import NewContentCard from '../../components/cards/NewContentCard'
import ArticleService from '../../services/articles.service'
import WebBanners from '../../components/banners/WebBanners'
import NewContentCardPlaceholder from '../../components/cards/NewContentCardPlaceholder'
import { useParams } from 'react-router'
import CategoryService from '../../services/category.service'
import { NavbarContext } from '../../Contexts'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'

function NewCategory() {
	const { t, i18n } = useTranslation();
	const { state: { categories, user, banners } } = useContext(AppContext);
	const { navbarState: { navbarHide }, navbarUpdate } = useContext(NavbarContext)
	const params = useParams();

	const [categoryDetail, setCategoryDetail] = useState();
	const [categoryPage, setCategoryPage] = useState(params?.category);
	const [categoryContext, setCategoryContext] = useState();

	const [articles, setArticles] = useState();
	const [offset, setOffset] = useState(0);
	const [totalArticles, setTotalArticles] = useState();

	const [filterList, setFilterList] = useState([])
	const [filterType, setFilterType] = useState()

	const [showSwiper, setShowSwiper] = useState(false)

	const cancelRequest = useRef()

	const [mobileViewport, setMobileViewport] = useState(window.innerWidth <= 425)
    const stickyRef = useStickyBox({ offsetTop: !mobileViewport ? 59 : navbarHide ? 0 : 55 })


	const getCategoryDetail = async () => {
		try {
			const category = categories.find(category => category?.slugs[i18n.language] === params.category);
			const details = await CategoryService.getCategoriesDetail(category?.slugs[i18n.language]);
			setCategoryDetail(details)
			setCategoryContext(category)
		} catch (error) {
			console.error(error);
		}
	}

	const getArticles = async () => {
		try {
			const data = await ArticleService.getArticles({ length: 6, categoryIds: categoryDetail?.id, type: filterType, offset: offset })

			if (articles?.length) {
				setArticles([
					...articles,
					...data?.content
				])
			} else {
				setArticles(data?.content)
			}

			setTotalArticles(data?.totalElements)
			setOffset(offset + 1)
		} catch (error) {
			console.error(error);
		}
	}

	const handleFilter = (type) => {
		const localFilterList = [...filterList];
		const filterIndex = localFilterList.indexOf(type);

		if (filterIndex >= 0) {
			localFilterList.splice(filterIndex, 1)
			setFilterList(localFilterList)
		} else {
			setFilterList([
				...localFilterList,
				type
			])
		}
	}

	useEffect(() => {
		if (filterList?.includes('reviews') && filterList?.includes('articles')) {
			setFilterType(null);
		} else if (filterList?.includes('reviews') && !filterList?.includes('articles')) {
			setFilterType(2)
		} else if (!filterList?.includes('reviews') && filterList?.includes('articles')) {
			setFilterType(1)
		} else {
			setFilterType(null);
		}
	}, [filterList])

	useEffect(() => {
		if (articles) {
			cancelRequest.current = setTimeout(() => {
				setArticles()
				setOffset(0)
			}, 1000);
		}

		return () => {
			clearTimeout(cancelRequest.current)
		}
	}, [filterType])

	useEffect(() => {
		if (offset === 0 && categoryDetail) {
			getArticles([]);
		}
	}, [categoryDetail])

	useEffect(() => {
		if (categories?.length > 0 && categoryPage) {
			getCategoryDetail();
		}
	}, [categories, categoryPage])

	useEffect(() => {
		if (params.category !== categoryPage) {
			setArticles(); // reset
			setCategoryDetail()
			setOffset(0)
			setTotalArticles()

			setCategoryPage(params.category)
		} else {
			setCategoryPage(params.category)
		}
	}, [params])

	useEffect(() => {
		if (offset === 0 && categoryDetail) {
			getArticles();
		}
	}, [offset])

	useEffect(() => {
        let waitResize;		
		
        const checkResize = () => {
			setMobileViewport(window.innerWidth <= 425)
        }

        window.addEventListener('resize', () => {			
            clearTimeout(waitResize);
            waitResize = setTimeout(checkResize, 100)
        })

        return () => window.removeEventListener('resize', checkResize)
	}, [])


	return (
		<>
			{categoryDetail && <SeoHelmet title={t(`seo.guide.categories.${[categoryDetail.slugs?.['en']]}.title`)} metaTitle={t(`seo.guide.categories.${[categoryDetail.slugs?.['en']]}.metaTitle`)} metaDescription={t(`seo.guide.categories.${[categoryDetail.slugs?.['en']]}.metaDescription`)} ogImage={'/img/home-left-1.png'} />}
			<main>
				<section className='container-fluid px-0 position-relative bg-laurel guide-header-category border-bottom border-white'>
					<div className='d-flex justify-content-center align-items-center overlay'>
						<div className='d-flex flex-column justify-content-center align-items-center gap-3 container-lg'>
							<Image src={categoryContext?.logoUrl} fluid className='icon' />
							<p className='text-center text-white inter-regular mb-0 letter-spacing-1 text-uppercase fs-6'>{categoryContext?.name}</p>
						</div>
					</div>
				</section>
				<section className={`bg-laurel guide-category-filter ${!showSwiper ? "d-none" : ""}`.trim()} ref={stickyRef}>
					<div className='px-0 px-lg-4 container-lg'>
						<Swiper
							navigation
							slidesPerView={'auto'}
							spaceBetween={32}
							slidesOffsetBefore={24}
							slidesOffsetAfter={24}
							className='w-100 py-3'
							shortSwipes
							longSwipes
							freeMode
							modules={[FreeMode, Navigation]}
							onInit={() => {
								setShowSwiper(true)
							}}
						>
							<SwiperSlide>
								<Form.Check
									id='reviews'
									className='tt-norms d-flex flex-row align-items-center filter-item justify-content-center mb-0 inter-medium text-white letter-spacing-1'
									label={t("guide.filter.reviews")}
									onChange={() => handleFilter("reviews")}
									checked={filterList.includes("reviews") ? true : false}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<Form.Check
									id='articles'
									className='tt-norms d-flex flex-row align-items-center filter-item justify-content-center mb-0 inter-medium text-white letter-spacing-1'
									label={t("guide.filter.articles")}
									onChange={() => handleFilter("articles")}
									checked={filterList.includes("articles") ? true : false}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<Form.Check
									className='tt-norms d-flex flex-row align-items-center filter-item justify-content-center mb-0 inter-medium text-white letter-spacing-1'
									id='recent'
									label={t("guide.filter.recent")}
									onChange={() => handleFilter("recent")}
									checked={filterList.includes("recent") ? true : false}
								/>
							</SwiperSlide>
						</Swiper>
					</div>
				</section>
				<section className='container-fluid px-0 position-relative guide-title-category border-bottom border-coal-semi-transparent d-flex justify-content-center align-items-center py-5'>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='d-flex flex-column justify-content-center align-items-center gap-3 container-lg'>
							<h1 className='text-center text-black fitzgerald-regular lh-sm mb-0'>
								{`${categoryDetail?.title || ""}`.trim()}
							</h1>
							<p className='text-center inter-regular fs-6 fs-lg-5 text-black mb-2 mb-lg-0'>
								{`${categoryDetail?.description || ""}`.trim()}
							</p>
						</div>
					</div>
				</section>
				<section className='border-bottom border-coal-semi-transparent'>
					<div className='d-flex flex-column container-lg py-5 '>
						{!articles ? (
							<Row className='gy-5 gx-4 mb-5'>
								{[1, 2, 3, 4, 5, 6].map((i) => (
									<Col lg={4} key={i}>
										<NewContentCardPlaceholder />
									</Col>
								))}
							</Row>
						) : (
							<>
								<Row className='gy-5 gx-4 mb-5'>
									{articles?.map((article, i) => (
										<Col lg={4} key={i}>
											<NewContentCard theme={"laurel"} image={article?.listImageUrl} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
										</Col>
									))}
								</Row>
								{articles?.length < totalArticles && <Button className='btn-laurel-outline letter-spacing-1 inter-regular py-3 px-5 align-self-center' onClick={() => getArticles()}>{t('general.components.seeMore')}</Button>}
							</>
						)}
					</div>
				</section>
				<section className='border-bottom border-coal-semi-transparent bg-beige'>
					<div className='px-0 px-lg-4 container-lg py-5'>
						<WebBanners />
					</div>
				</section>
				<section className='container-lg pb-5 pt-5'>
					<Row className='justify-content-center align-items-center'>
						<Col md={12} lg={12} className="instagram-widget">
							<div className='d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-end mb-3'>
								<span className='inter-medium fs-5 mb-2 text-dark-green lh-1'>{t('miniGuide.follow')} @traveltozero</span>
							</div>
							<div>
								<div className="elfsight-app-34b7b62a-350f-4b31-8fbc-794e7795858f"></div>
							</div>
						</Col>
					</Row>
				</section>
			</main>
		</>
	)
}

export default NewCategory