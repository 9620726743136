import { AppContext } from '../../AppContext';
import { Image } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Link from '../Link';
import React, { useContext, useEffect, useState } from 'react'
import TzoNav from '../svg-component/TzoNav';
import ExploreNav from '../svg-component/ExploreNav';
import MustNav from '../svg-component/MustNav';
import GuideNav from '../svg-component/GuideNav';
import FavoriteNav from '../svg-component/FavoriteNav';
import { NavbarContext } from '../../Contexts';

function MobileNav({ theme }) {

	const { state: { user, showBottomNav } } = useContext(AppContext);
    const { navbarState: { navbarHide, bottomNavHide }, navbarState, navbarUpdate } = useContext(NavbarContext)

	const { t, i18n } = useTranslation();
	const location = useLocation();

	const [currentSection, setCurrentSection] = useState();

	useEffect(() => {
		const section = location.pathname.split('/')[2]
		setCurrentSection(section)
	}, [location])

	return (
		<nav className={`d-flex d-md-none flex-row flex-nowrap mobile-nav pt-2 pb-3 justify-content-between flex-grow-1 flex-shrink-1 gap-0 bg-dark-brown ${theme} card-shadow ${!bottomNavHide ? "show" : "hide"} ${!showBottomNav ? "d-none" : ""}`.trim()}>
			<Link href={t('pages.explore')} className={`d-flex flex-column gap-1 section ${theme} ${currentSection === t("pages.explore") ? "active" : ""}`.trim()}>
				<ExploreNav theme={theme} />
				<span className='tt-norms-bold small text-center'>{t('navbar.explore')}</span>
			</Link>
			<Link href={t('pages.miniGuide')} className={`d-flex flex-column gap-1 section ${theme} ${currentSection === t("pages.miniGuide") ? "active" : ""}`.trim()}>
				<MustNav theme={theme} />
				<span className='tt-norms-bold small text-center'>{t('navbar.must')}</span>
			</Link>
			<Link className={`d-flex flex-column gap-1 section ${theme} ${!currentSection ? "active" : ""}`.trim()}>
				<TzoNav theme={theme} />
				<span className='tt-norms-bold small text-center'>{t('navbar.home')}</span>
			</Link>
			<Link href={t('pages.guide')} className={`d-flex flex-column gap-1 section ${theme} ${currentSection === t("pages.guide") ? "active" : ""}`.trim()}>
				<GuideNav theme={theme} />
				<span className='tt-norms-bold small text-center text-nowrap'>{t('navbar.guide')}</span>
			</Link>
			<Link href={user ? `${t('pages.profile')}/${t('pages.favourites')}` : `${t("pages.login")}`} className={`d-flex flex-column align-items-center gap-1 section ${theme} ${currentSection === t("pages.profile") ? "active" : ""}`.trim()}>
				<FavoriteNav theme={theme} />
				<span className='tt-norms-bold small text-center text-nowrap'>{t('navbar.profile.favorite')}</span>
			</Link>
			{/* <Link href={t('pages.villas')} className='d-flex flex-column gap-1 section'>
				<Image height={20} src="/img/navigation/villas-nav.svg" />
				<span className='tt-norms text-white small text-center'>{"Villas" || t("navbar.villas")}</span>
			</Link> */}
		</nav>
	)
}

export default MobileNav