import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState, useCallback } from "react";
import Link from "../../components/Link";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ArticleService from "../../services/articles.service";
import AddThis from "../../components/addthis/AddThis";
import { AppContext } from "../../AppContext";
import SubscribeModal from "../../components/modals/SubscribeModal";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";
import ArticleSchema from "../../components/schema/ArticleSchema";
import UserDataService from "../../services/userData.service";
import { FavouritesContext, MiniGuideContext, NavbarContext } from "../../Contexts";
import useDrag from "../../components/horizontal-scroll/useDrag";
import { useRef } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import useHandleFavorite from "../../hooks/useHandleFavorite";
import NewContentCard from "../../components/cards/NewContentCard";
import NewArticleReview from "../../components/article-review/NewArticleReview";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import BookmarkIcon from "../../components/icons/BookmarkIcon";
import ArticleLimit from "../../components/article-limit/ArticleLimit";
import MutateObserver, { } from "@rc-component/mutate-observer";
import Portal from "@rc-component/portal";

const NewArticle = () => {

    const { t, i18n } = useTranslation();
    const { state: { user }, state, update } = useContext(AppContext);
    const { miniGuideState: { reviewIndex, visitedReview, articles, format }, miniGuideState, miniGuideUpdate } = useContext(MiniGuideContext);
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext)
    const { navbarState, navbarUpdate } = useContext(NavbarContext)
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
    })

    const { handleFavourite, removeFavourite, isFavorite, isFavoriteActive, setIsFavoriteActive, addingFlag, setAddingFlag } = useHandleFavorite()

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { dragStart, dragStop, dragMove, dragging } = useDrag();

    const handleDrag = ({ scrollContainer }) => (ev) => dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
        }
    });

    const scrollMenuApiRef = useRef();

    const [article, setArticle] = useState();
    const [recomendations, setRecomendations] = useState();
    const [articleSlug, setArticleSlug] = useState();
    const [show, setShow] = useState(false);

    const [currentReview, setCurrentReview] = useState();
    const [prevReview, setPrevReview] = useState();
    const [nextReview, setNextReview] = useState();
    const [relatedReviews, setRelatedReviews] = useState();
    const [centerRanking, setCenterRanking] = useState();

    const [domArticleLimit, setDomArticleLimit] = useState()
    const [reRenderLimit, setReRenderLimit] = useState(false)
    const [domObsverver, setDomObserver] = useState()

    const [showLimiter, setShowLimiter] = useState(false)
    const [refreshLimiter, setRefreshLimiter] = useState(false)
    const [test, setTest] = useState(true)

    const handleClose = () => setShow(false);

    const [map, setMap] = useState(null); // map instance
    const onLoadMap = useCallback(setMap, [setMap]);

    useEffect(() => {
        if (map && article?.interestSites && window.google) {
            const bounds = new window.google.maps.LatLngBounds()

            article?.interestSites.map((site) => {
                bounds.extend({ lat: site?.latitude, lng: site?.longitude })
            })

            if (article?.interestSites.length === 1) {
                map.setZoom(18)
                map.setCenter({ lat: article?.interestSites?.[0].latitude, lng: article?.interestSites?.[0].longitude })
            } else {
                map.fitBounds(bounds, 200)
            }
        }
    }, [map])


    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    useEffect(() => {
        UserDataService.visitGuide();
    }, [])

    useEffect(() => {

        const getArticle = async () => {
            try {
                const category = state.categories.find(category => category.slugs[i18n.language] === params.category);
                const data = await ArticleService.getArticle(params.slug, category.id);
                update({
                    ...state,
                    detailSlugs: data.slugs
                });

                setArticle(data);
            } catch (error) {
                navigate(`/${i18n.language}/${t('pages.guide')}`);
            }
        }

        if (state.categories.length > 0 && params.slug !== articleSlug) {
            setArticle();
            setRecomendations();
            setArticleSlug(params.slug)
            getArticle();
            window.scrollTo({ top: 0, behavior: "instant" })
        }
    }, [params.slug, state.categories, articleSlug, i18n.language, params.category, state, update, navigate, t]);

    useEffect(() => {
        const getRecomendations = async () => {
            try {
                const tags = article.tags
                const category = state.categories.find(category => category.slugs[i18n.language] === params.category);

                let articles
                let tagsId = "";

                tags.forEach((tag, i) => {
                    if (i !== tags.length - 1) {
                        tagsId += `${tag.id}, `
                    } else {
                        tagsId += tag.id
                    }
                });
                if (article.type === 1) {
                    articles = await ArticleService.getArticleByTags(tagsId, 3, article.id);
                } else if (article.type === 2) {
                    articles = await ArticleService.getArticleByCategory(category?.id, 3, 0, null, article.id);
                }

                (articles?.content.length > 0 && setRecomendations(articles?.content))
            } catch (error) {
                navigate(`/${i18n.language}/${t('pages.guide')}`);
            }
        }

        if (article && !recomendations) {
            getRecomendations();
        }
    }, [article, i18n.language, navigate, t, recomendations])

    useEffect(() => {
        if (!visitedReview && reviewIndex >= 0 && article?.type === 1) {
            miniGuideUpdate({
                ...miniGuideState,
                visitedReview: true
            })
        }
    }, [reviewIndex, article])

    useEffect(() => {
        if (article && articles) {
            let currentReview
            let displayReviews = [...articles];

            articles.forEach((miniGuideArticle, i) => {
                if (miniGuideArticle?.slugs?.[i18n.language] === article?.slugs?.[i18n.language]) {
                    currentReview = i
                }
            })

            setCurrentReview(currentReview)
            setPrevReview((currentReview - 1) >= 0 && articles?.[currentReview - 1])
            setNextReview((currentReview + 1) < (articles?.length) && articles?.[currentReview + 1])
            displayReviews.splice(currentReview, 1);
            setRelatedReviews(displayReviews)
        }
    }, [articles, article, location])

    useEffect(() => {

        if (scrollMenuApiRef?.current?.isLastItemVisible && scrollMenuApiRef?.current?.initComplete) {
            setCenterRanking(true)
        } else if (scrollMenuApiRef?.current?.initComplete) {
            setCenterRanking(false)
        }
    })

    useEffect(() => {
        if (article) {
            setIsFavoriteActive(isFavorite("ARTICLE", article?.id));
        }
    }, [article])

    const mutateLimiter = (mutations, observer) => {
        mutations.forEach(mutation => {
            if (mutation?.type === "attributes") {
                if (mutation?.target?.id === "article-limit") {
                    setShowLimiter(false)
                    setRefreshLimiter(!refreshLimiter)
                } else if (Array.from(mutation?.target?.classList).includes("article-limit")) {
                    setShowLimiter(false)
                    setRefreshLimiter(!refreshLimiter)
                }
            }

            else if (mutation?.removedNodes?.length > 0) {
                mutation?.removedNodes?.forEach((el) => {
                    const limiterClasses = "position-absolute bottom-0 start-50 translate-middle-x w-100 end-0 article-limit z-index-3"
                    const isRendered = document.getElementById("article-limit");

                    if (el?.id === "article-limit" && el?.className === limiterClasses && !isRendered) {
                        document.getElementsByTagName('main')?.[0]?.appendChild(el)
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (article?.privateArticle && !user) {
            setShowLimiter(true)
        }
    }, [article, refreshLimiter, user])

    const limiterDetect = () => {

        let screenConditionalStart = null
        let screenConditionalEnd = null
        let isBottom = (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 342

        if (window.innerWidth >= 992) {
            screenConditionalStart = window.scrollY >= document.getElementById('article-limit').offsetTop + 61
            screenConditionalEnd = window.scrollY < document.getElementById('article-limit').offsetTop + 61
        } else {
            screenConditionalStart = window.scrollY >= document.getElementById('article-limit').offsetTop + 55
            screenConditionalEnd = window.scrollY < document.getElementById('article-limit').offsetTop + 55
        }

        if (isBottom && window.innerWidth >= 992) {
            document.getElementById('form-container').scrollTo(0, 0)
            document.getElementById('form-container').removeAttribute("style")

            document.getElementsByClassName("login-container")[0].childNodes?.[0]?.classList.add("h-100")
            document.getElementById('form-container')?.classList.add("h-100")

        } else if (screenConditionalStart && !navbarState?.blockNavHide) {
            navbarUpdate({
                ...navbarState,
                navbarHide: false,
                bottomNavHide: false,
                blockNavHide: true,
            })

            document.getElementById('form-container').style.overflowY = "auto"
            document.getElementById('form-container').scrollTo({ top: 0, behavior: "instant" })

        } else if (screenConditionalEnd && navbarState?.blockNavHide) {
            navbarUpdate({
                ...navbarState,
                blockNavHide: false,
            })

            document.getElementById('form-container').scrollTo({ top: document.getElementById('form-container').scrollHeight, behavior: "instant" })
            document.getElementById('form-container').removeAttribute("style")
        }
        
        if (screenConditionalStart && !document.getElementById('form-container').style.overflowY && !isBottom) {            
            document.getElementById('form-container').style.overflowY = "auto"
            document.getElementsByClassName("login-container")[0].childNodes?.[0]?.classList.remove("h-100")
            document.getElementById('form-container')?.classList.remove("h-100")
            document.getElementById('form-container').scrollTo({ top: document.getElementById('form-container').scrollHeight, behavior: "instant" })
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', limiterDetect)
        window.addEventListener('scrollend', limiterDetect)

        return () => {
            window.removeEventListener('scroll', limiterDetect)
            window.removeEventListener('scrollend', limiterDetect)
        }
    }, [navbarState?.blockNavHide])

    useEffect(() => {
        if (document.getElementById('article-limit')) {
            limiterDetect()
        }
    }, [navbarState?.blockNavHide])

    return (
        <>
            {article && article?.type === 1 &&
                <>
                    <SeoHelmet title={`${article.metaTitle}`} metaTitle={`${article.metaTitle}`} metaDescription={article.metaDescription} ogImageUrl={article.imgListUrl} schemaType={"article"} schemaData={article} />
                    <ArticleSchema article={article} />
                    {/* <BreadcrumbPage crumbArticle={article?.breadcrumbsTitle} category={article?.categoryName} /> */}
                    <MutateObserver onMutate={mutateLimiter} options={{ attributes: true, childList: true, subtree: true }}>
                        <main className="bg-beige position-relative">
                            {(!isNaN(currentReview) && relatedReviews) ? (
                                format === 2 ? (
                                    <div className='d-flex flex-row justify-content-between align-items-center mb-3 article-pagination py-3 mt-4 text-white bg-laurel'>
                                        <div className={`d-flex flex-row align-items-center justify-content-between justify-content-lg-start gap-1 position-relative px-4 flex-grow-1 container`}>
                                            {prevReview && (
                                                <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                                </Link>
                                            )}
                                            {format === 2 && (
                                                <>
                                                    <span className={`tt-norms ${prevReview ? "ps-3" : ""} clamp-1`.trim()}>{`${articles?.[currentReview]?.titleFirstPart} ${articles?.[currentReview]?.titleSecondPart}`.trim()}</span>
                                                    <span className='tt-norms px-3'>{currentReview + 1}/{articles?.length}</span>
                                                </>
                                            )}
                                            {nextReview && (
                                                <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                                </Link>
                                            )}
                                            <div className="content-card custom-fav ms-3 shadow-none w-auto">
                                                <div className={`fav-container p-2 p-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                                    if (user) {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (isFavoriteActive) {
                                                            setAddingFlag(true);
                                                            removeFavourite("ARTICLE", article?.id);
                                                        } else {
                                                            setAddingFlag(true);
                                                            handleFavourite("ARTICLE", article?.id);
                                                        }
                                                    } else {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='text-white bg-laurel py-3 mt-4'>
                                            <div className='container d-flex flex-row'>
                                                <Row className='g-0 g-lg-3 d-lg-none flex-grow-1'>
                                                    <Col xs={1} lg={"auto"} className='d-flex align-items-center'>
                                                        {prevReview && (
                                                            <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                                            </Link>
                                                        )}
                                                    </Col>
                                                    <Col xs={8} lg={"auto"} className='d-flex align-items-center'>
                                                        <div className='ranking-pagination'>
                                                            <ScrollMenu
                                                                onMouseDown={() => dragStart}
                                                                onMouseUp={() => dragStop}
                                                                onMouseMove={handleDrag}
                                                                scrollContainerClassName={`ranking-pagination ${(centerRanking || scrollMenuApiRef?.current?.isLastItemVisible) ? "d-flex justify-content-center" : ""}`.trim()}
                                                                apiRef={scrollMenuApiRef}
                                                                onUpdate={() => {
                                                                    if (centerRanking !== false) {
                                                                        setCenterRanking(scrollMenuApiRef?.current?.isLastItemVisible)
                                                                    } else if (centerRanking === undefined) {
                                                                        setCenterRanking(false)
                                                                    }
                                                                }}
                                                            >
                                                                {articles.map((article, i) => (
                                                                    <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className="">
                                                                        <div className={`d-flex align-items-center justify-content-center ranking tt-norms-bolder ${currentReview === i ? "active" : ""}`.trim()}>
                                                                            {articles?.length - i}
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </ScrollMenu>
                                                        </div>
                                                    </Col>
                                                    <Col xs={1} lg={"auto"} className='d-flex align-items-center justify-content-end'>
                                                        {nextReview && (
                                                            <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                                            </Link>
                                                        )}
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div className="content-card custom-fav d-flex justify-content-center shadow-none">
                                                            <div className={`fav-container p-2 p-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                                                if (user) {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    if (isFavoriteActive) {
                                                                        setAddingFlag(true);
                                                                        removeFavourite("ARTICLE", article?.id);
                                                                    } else {
                                                                        setAddingFlag(true);
                                                                        handleFavourite("ARTICLE", article?.id);
                                                                    }
                                                                } else {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                                }
                                                            }}>
                                                                <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='g-0 g-lg-3 d-lg-flex d-none'>
                                                    <Col lg={"auto"} className='d-flex align-items-center'>
                                                        {prevReview && (
                                                            <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                                            </Link>
                                                        )}
                                                    </Col>
                                                    <Col lg={"auto"} className='d-flex align-items-center'>
                                                        <div className='ranking-pagination'>
                                                            <ScrollMenu
                                                                onMouseDown={() => dragStart}
                                                                onMouseUp={() => dragStop}
                                                                onMouseMove={handleDrag}
                                                                scrollContainerClassName={`ranking-pagination ${(centerRanking || scrollMenuApiRef?.current?.isLastItemVisible) ? "d-flex justify-content-center" : ""}`.trim()}
                                                                apiRef={scrollMenuApiRef}
                                                                onUpdate={() => {
                                                                    if (centerRanking !== false) {
                                                                        setCenterRanking(scrollMenuApiRef?.current?.isLastItemVisible)
                                                                    } else if (centerRanking === undefined) {
                                                                        setCenterRanking(false)
                                                                    }
                                                                }}
                                                            >
                                                                {articles.map((article, i) => (
                                                                    <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className="">
                                                                        <div className={`d-flex align-items-center justify-content-center ranking tt-norms-bolder ${currentReview === i ? "active" : ""}`.trim()}>
                                                                            {articles?.length - i}
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </ScrollMenu>
                                                        </div>
                                                    </Col>
                                                    <Col lg={"auto"} className='d-flex align-items-center justify-content-end'>
                                                        {nextReview && (
                                                            <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                                            </Link>
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <div className="content-card shadow-none">
                                                            <div className={`mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`}>
                                                                <BookmarkIcon
                                                                    width={18}
                                                                    className={`favorite-icon cursor-pointer ${(isFavoriteActive || addingFlag) ? "text-coal bookmark-active" : "text-beige bookmark"}`}
                                                                    onClick={(e) => {
                                                                        if (user) {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            if (isFavoriteActive) {
                                                                                setAddingFlag(true);
                                                                                removeFavourite("ARTICLE", article?.id);
                                                                            } else {
                                                                                setAddingFlag(true);
                                                                                handleFavourite("ARTICLE", article?.id);
                                                                            }
                                                                        } else {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </>
                                )
                            ) : (
                                <div className='text-white bg-laurel py-2 d-flex flex-row'>
                                    <Container>
                                        <div className="content-card custom-fav w-auto d-flex flex-row shadow-none">
                                            <div className={`d-flex flex-row justify-content-between justify-content-lg-start align-items-center gap-3 flex-grow-1 cursor-pointer fav-container px-0 py-2 py-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`}>
                                                <p className='inter-regular text-white h6 mb-0'>{isFavoriteActive ? t('articles.favorite.remove') : t('articles.favorite.add')}</p>
                                                <BookmarkIcon
                                                    width={18}
                                                    className={`favorite-icon cursor-pointer ${(isFavoriteActive || addingFlag) ? "text-coal bookmark-active" : "text-beige bookmark"}`}
                                                    onClick={(e) => {
                                                        if (user) {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            if (isFavoriteActive) {
                                                                setAddingFlag(true);
                                                                removeFavourite("ARTICLE", article?.id);
                                                            } else {
                                                                setAddingFlag(true);
                                                                handleFavourite("ARTICLE", article?.id);
                                                            }
                                                        } else {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Container>
                                </div>
                            )}
                            <Container as={"article"} className="position-relative">
                                <Row className="mt-4 mb-lg-4 flex-grow-1">
                                    <Col lg={7}>
                                        <h2 className="fitzgerald-regular mb-0">{article?.projectTitle}</h2>
                                        <h1 className="inter-medium h2 mb-2">{`${article?.titleFirstPart} ${article?.titleSecondPart}`.trim()}</h1>
                                        <div className="mb-4">
                                            {article?.articleAuthor && (
                                                <p className="mb-2 joane-bold small">{t('articles.author')} {article?.articleAuthor}</p>
                                            )}
                                            {article?.articlePhotographer && (
                                                <p className="mb-2 joane-bold small">{t('articles.photographer')} {article?.articlePhotographer}</p>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <AddThis noSpace={true} url={window.location.href} title={article.metaTitle} description={article.articleShortDescription} img={article.img1Url} facebook linkedin whatsapp instagram />
                                        </div>
                                        {article?.articleShortDescription && (
                                            <p className="fitzgerald-regular opacity-75 fs-5 mb-4">{article?.articleShortDescription}</p>
                                        )}
                                        {article?.img1Url && (
                                            <div>
                                                <Image src={article?.img1Url} fluid className="h-100 fit-cover article-image d-lg-none mb-4" />
                                            </div>
                                        )}
                                        {article?.textBlock1 && (
                                            <p className="inter-regular text-coal" dangerouslySetInnerHTML={{ __html: article?.textBlock1 }} />
                                        )}
                                        {article?.quote1 && (
                                            <p className="inter-regular text-coal text-center" dangerouslySetInnerHTML={{ __html: article?.quote1 }} />
                                        )}
                                    </Col>
                                    <Col lg={5} className="mh-100 flex-grow-1">
                                        <div className="position-relative h-100">
                                            <Image src={article?.img1Url} fluid className="position-absolute h-100 w-100 fit-cover" />
                                        </div>
                                    </Col>
                                </Row>
                                {article?.textBlock2 && (
                                    <Row>
                                        <Col>
                                            <p className="inter-regular text-coal" dangerouslySetInnerHTML={{ __html: article?.textBlock2 }} />
                                        </Col>
                                    </Row>
                                )}
                                {(article?.img2Url || article?.img3Url) && (
                                    <Row className="mb-4">
                                        {article?.img2Url && (
                                            <Col lg={article?.img3Url ? 6 : 12} className={`${article?.img3Url ? "mb-3 mb-lg-0" : ""}`}>
                                                <Image src={article?.img2Url} fluid className={`h-100 fit-cover ${article?.img3Url ? "article-image-half mb-2 mb-lg-0" : ""}`.trim()} />
                                            </Col>
                                        )}
                                        {article?.img3Url && (
                                            <Col lg={article?.img2Url ? 6 : 12}>
                                                <Image src={article?.img3Url} fluid className={`h-100 fit-cover ${article?.img2Url ? "article-image-half" : ""}`.trim()} />
                                            </Col>
                                        )}
                                    </Row>
                                )}
                                {article?.quote2 && (
                                    <Row>
                                        <Col lg={12}>
                                            {article?.quote2 && (
                                                <p className="inter-regular text-coal text-center" dangerouslySetInnerHTML={{ __html: article?.quote2 }} />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {article?.textBlock3 && (
                                    <Row>
                                        <Col lg={12}>
                                            {article?.textBlock3 && (
                                                <p className="inter-regular text-coal text-center" dangerouslySetInnerHTML={{ __html: article?.textBlock3 }} />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {article?.img4Url && (
                                    <Row className="mb-4">
                                        {article?.img4Url && (
                                            <Col lg={12}>
                                                <Image src={article?.img4Url} fluid className="h-100 fit-cover article-image" />
                                            </Col>
                                        )}
                                    </Row>
                                )}
                                {article?.quote3 && (
                                    <Row>
                                        <Col lg={12}>
                                            {article?.quote3 && (
                                                <p className="inter-regular text-coal text-center" dangerouslySetInnerHTML={{ __html: article?.quote3 }} />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {/* {article?.textBlock4 && (!article?.privateArticle || (user && article?.privateArticle)) && <div dangerouslySetInnerHTML={{ __html: article?.textBlock4 }} />} */}
                            </Container>
                            <Container as={"aside"} className="mt-5">
                                {(article?.interestSites?.length > 0 && isLoaded) && <div className="site-detail-map mb-5 position-relative">
                                    <GoogleMap
                                        onLoad={onLoadMap}
                                        mapContainerStyle={containerStyle}
                                        // center={article?.interestSites?.length === 1 ? { lat: article?.interestSites?.[0].latitude, lng: article?.interestSites?.[0].longitude } : { lat: 39.620349, lng: 2.905260 }}
                                        // zoom={18}
                                        options={{
                                            clickableIcons: false,
                                            disableDefaultUI: true,
                                            fullscreenControl: true,
                                            zoomControl: true,
                                            styles: [
                                                {
                                                    "featureType": "poi.business",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                                {
                                                    "featureType": "poi.school",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                                {
                                                    "featureType": "poi.sports_complex",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                            ]
                                        }}
                                    >
                                        {article?.interestSites.map((site, i) => (
                                            ((site.latitude && site.longitude) &&
                                                <Marker
                                                    key={i}
                                                    position={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }}
                                                />
                                            )
                                        ))}
                                    </GoogleMap>
                                </div>}
                                {article?.interestSites?.length > 0 && (
                                    <>
                                        <Row className="gy-5 pb-5 gx-5">
                                            {article?.interestSites.map((site, i) => (
                                                <Col key={i} xs={12} md={6} xl={article?.interestSites.length === 2 ? 6 : article?.interestSites.length === 1 ? 12 : 4} className="site-info-container d-flex flex-column justify-content-between">
                                                    <div className="d-flex flex-column gap-4">
                                                        <p className="h2 joane-bolder mb-0 tzo-title">{site?.name}</p>
                                                        {site?.name && (site?.latitude && site?.longitude) && (
                                                            <div>
                                                                <a href={`https://www.google.com/maps/dir/?api=1&destination=${site?.latitude},${site?.longitude}`} target={"_blank"} className="text-dark text-decoration-none">
                                                                    <div className='d-flex flex-row align-items-center cursor-pointer gap-3 site-info'>
                                                                        <span className='site-icon align-items-center site-icon'>
                                                                            <FontAwesomeIcon icon="fa-solid fa-location-dot" className='text-dark icon align-self-center' />
                                                                        </span>
                                                                        <p className='mb-0 tt-norms-bold h6'>{site?.address}</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        )}
                                                        {site?.webSiteUrl && (
                                                            <div>
                                                                <a href={site?.webSiteUrl} className='mb-0 tt-norms-bold h6 text-dark text-decoration-none' target={"_blank"}>
                                                                    <div className='d-flex flex-row align-items-center cursor-pointer gap-3 site-info'>
                                                                        <span className='site-icon align-items-center site-icon'>
                                                                            <FontAwesomeIcon icon="fa-solid fa-globe" className='text-dark icon align-self-center' />
                                                                        </span>
                                                                        <p className='mb-0 tt-norms-bold h6 text-break'>{site?.webSiteUrl?.split('/')[2] || site?.webSiteUrl}</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        )}
                                                        {site?.schedule && (
                                                            <div className='d-flex flex-row align-items-center gap-3 site-info text-dark d-flex flex-grow-0'>
                                                                <span className='border border-2 p-1 site-icon align-items-center site-icon'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-clock" className='text-dark icon align-self-center' />
                                                                </span>
                                                                <p className='mb-0 tt-norms-bold h6'>{site?.schedule}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Link href={`${t('pages.explore')}/${site?.categorySlug}/${site?.slugs?.[i18n.language]}`} className={"align-self-start mt-5"}><Button className="px-5 radius btn-dark-green-outline">{t('miniGuide.card.button')}</Button></Link>
                                                    <hr className="d-block d-md-none mb-0 mt-5" />
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                )}
                                {/* <div className="d-none d-sm-flex justify-content-center align-items-center gap-3 mb-5 flex-wrap">
                                {article?.tags.map((tag, i) => (
                                    <div key={i}>
                                        <span className={`${i !== 0 ? "opacity-50" : ""} joane-bold text-uppercase mb-0 h5`.trim()}>#{tag.name}</span>
                                        <span className="tag-separator py-3" />
                                    </div>
                                ))}
                            </div> */}
                            </Container>
                            {recomendations?.length > 0 &&
                                <Container as={"section"} className="pb-5 d-none d-lg-block">
                                    <p className="fitzgerald-regular mb-5 h3 border-bottom border-coal-semi-transparent">{t('miniGuide.suggestedOptions')}</p>
                                    <Row className='gy-5 gx-4 sites-custom-cards'>
                                        {recomendations?.map((recomendation, i) =>
                                            <Col lg={4} key={recomendation?.id}>
                                                <NewContentCard theme={"laurel"} image={recomendation?.listImageUrl} project={recomendation?.projectTitle} category={recomendation?.categoryTitle} title={`${recomendation?.titleFirstPart || ""} ${recomendation?.titleSecondPart || ""}`.trim()} stamp={recomendation?.stamp?.image} slug={`${t('pages.guide')}/${recomendation?.categorySlug}/${recomendation?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={recomendation?.id} data={recomendation} />
                                            </Col>
                                        )}
                                    </Row>
                                </Container>
                            }
                            {recomendations?.length > 0 && <section className='pb-4 bg-beige d-lg-none'>
                                <p className="fitzgerald-regular mb-4 h2 border-bottom border-coal-semi-transparent pe-4 ms-4">{t('miniGuide.suggestedOptions')}</p>

                                <Swiper
                                    slidesPerView={1.15}
                                    spaceBetween={8}
                                    className='w-100 mb-2 d-lg-none'
                                    slidesOffsetBefore={window.innerWidth < 768 ? 24 : 0}
                                    slidesOffsetAfter={window.innerWidth < 768 ? 24 : 0}
                                    shortSwipes
                                    longSwipes
                                    freeMode
                                    modules={[FreeMode]}
                                >
                                    {recomendations?.map((recomendation, i) => (
                                        <SwiperSlide key={i}>
                                            <NewContentCard theme={"laurel"} image={recomendation?.listImageUrl} project={recomendation?.projectTitle} category={recomendation?.categoryTitle} title={`${recomendation?.titleFirstPart || ""} ${recomendation?.titleSecondPart || ""}`.trim()} stamp={recomendation?.stamp?.image} slug={`${t('pages.guide')}/${recomendation?.categorySlug}/${recomendation?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={recomendation?.id} data={recomendation} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </section>}
                            {(document.getElementsByTagName('main')?.length > 0 && !user) && (
                                <Portal open={showLimiter} getContainer={document.getElementsByTagName('main')?.[0]} autoLock={true}>
                                    <div className='position-absolute bottom-0 start-50 translate-middle-x w-100 end-0 article-limit z-index-3' id="article-limit">
                                        <div className='position-sticky top-0 sticky-container'>
                                            <div className='login-container'>
                                                <ArticleLimit article={article} />
                                            </div>
                                        </div>
                                    </div>
                                </Portal>
                            )}
                        </main>
                    </MutateObserver>
                </>
            }

            {
                article && article?.type === 2 && (
                    <MutateObserver onMutate={mutateLimiter} options={{ attributes: true, childList: true, subtree: true }}>
                        <NewArticleReview article={article} recomendations={recomendations} showLimiter={showLimiter} />
                    </MutateObserver>
                )
            }
            <SubscribeModal show={show} close={handleClose} />
        </>
    )
}

export default NewArticle
