import React, { useContext, useState } from 'react'
import { Button, Col, Container, Image, Row, Form, Alert } from "react-bootstrap"
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Link from '../../components/Link';
import { LoginSocialApple, LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { AppContext } from '../../AppContext';
import { FavouritesContext } from '../../Contexts';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import TokenService from '../../services/token.service';
import UserDataService from '../../services/userData.service';
import { useNavigate, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

function NewLogin() {
    const params = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { state: { shareToken, user, categories }, state, update } = useContext(AppContext)
    const { favouriteState: { favoriteRedirect }, favouriteState, favouriteUpdate } = useContext(FavouritesContext);

    const { register, handleSubmit, reset, resetField } = useForm({
        mode: "all",
        defaultValues: {}
    })

    const [errors, setErrors] = useState({
        login: false,
        server: false
    });

    const login = async (data) => {
        setErrors({
            login: false,
            server: false
        });

        try {
            await AuthService.login(data.email, data.password);
            const user = await UserService.userInfo();
            const userShareToken = TokenService.getShareFolderToken();
            const returnRedirectUrl = state?.returnRedirect

            update({
                ...state,
                user,
                returnRedirect: null
            });
            await UserDataService.updateUserInLogin();

            if (shareToken && !userShareToken) {
                let encodedParam = encodeURIComponent(shareToken);
                await TokenService.setShareFolderToken(encodedParam);
            }

            if (i18n.language !== UserDataService.getLanguagePreference()) {
                window.location.href = `${window.origin}/${UserDataService.getLanguagePreference()}/${shareToken ? t('pages.profile') : ""}`;
            } else {
                navigate(returnRedirectUrl || favoriteRedirect || `/${i18n.language}/${(shareToken || userShareToken) ? t('pages.profile') : ""}`)
            }
        } catch (error) {
            resetField("password")
            if (error.response.data.errorCode === 12011) {
                setErrors({ ...errors, login: true });
            } else {
                setErrors({ ...errors, server: true });
            }
        }
    }

    const socialLogin = async (provider, data) => {
        setErrors({
            server: false
        });
        try {
            const token = await AuthService.socialLoginToken({
                provider,
                token: data.access_token || data.accessToken || data.authorization.id_token,
                firstName: data.user !== undefined ? data.user.name.firstName : null,
                lastName: data.user !== undefined ? data.user.name.lastName : null
            })
            await TokenService.setUser(token)
            const user = await UserService.userInfo();
            const userShareToken = TokenService.getShareFolderToken();
            const returnRedirectUrl = state?.returnRedirect

            update({
                ...state,
                user,
                returnRedirect: null
            });
            await UserDataService.updateUserInLogin();

            if (shareToken && !userShareToken) {
                let encodedParam = encodeURIComponent(shareToken);
                await TokenService.setShareFolderToken(encodedParam);
            }

            if (i18n.language !== UserDataService.getLanguagePreference()) {
                window.location.href = `${window.origin}/${UserDataService.getLanguagePreference()}/${shareToken ? t('pages.profile') : ""}`;
            } else {
                navigate(returnRedirectUrl || favoriteRedirect || `/${i18n.language}/${(shareToken || userShareToken) ? t('pages.profile') : ""}`)
            }
        } catch (error) {
            setErrors({ ...errors, server: true });
        }
    }

    return (
        <>
            <SeoHelmet title={t('seo.login.title')} metaTitle={t('seo.login.metaTitle')} metaDescription={t('seo.login.metaDescription')} ogImage={'/img/home-left-1.png'} robots />
            <main>
                <Container fluid className='px-0 bg-beige login-container'>
                    <Row className='g-0'>
                        <Col lg={5} className='background-container d-none d-lg-block'>
                            <StickyBox offsetTop={60} className='bg-dark-brown'>
                                <div className='d-flex flex-column align-items-start gap-4 justify-content-center mx-auto register-content w-75'>
                                    <Image src='/img/svg/new-logo.svg' className='mb-4 align-self-start' width={350} />
                                    <h1 className='fitzgerald-regular fs-1 text-white'>{t('login.leftCard.title1')}</h1>
                                    <div className='d-flex flex-column gap-3'>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content1.bold')}</span> {t('register.leftCard.advantages.content1.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content2.bold')}</span> {t('register.leftCard.advantages.content2.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content3.bold')}</span> {t('register.leftCard.advantages.content3.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content4.bold')}</span> {t('register.leftCard.advantages.content4.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content5.bold')}</span> {t('register.leftCard.advantages.content5.text')}</p>
                                    </div>
                                    <p className='mb-0 fs-4 inter-regular text-white'>{t('register.leftCard.subtitle')}</p>
                                </div>
                            </StickyBox>
                        </Col>
                        <Col xs={12} lg={7} className='d-flex justify-content-center px-4 px-lg-0'>
                            <div className='d-flex flex-column align-items-center justify-content-center py-5 login-content'>
                                <h1 className='fitzgerald-regular fs-1 mb-4'>{t('login.card.title')}</h1>
                                <Form onSubmit={handleSubmit(login)} className='d-flex flex-column gap-3 w-100 mb-4'>
                                    <div className='d-flex flex-column gap-2'>
                                        <Form.Group>
                                            <Form.Control type="email" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.email')} {...register("email", {
                                                required: true, pattern: {
                                                    value: /\S+@\S+/
                                                },
                                            })} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.password')} {...register("password", { required: true })} required />
                                            {errors.login && <span className='error' />}
                                        </Form.Group>
                                    </div>
                                    <Link href={`${t('pages.recoverPassword')}`} className='inter-bold text-brown text-center'>{t('login.card.reset')}</Link>
                                    <Form.Check
                                        id='test1'
                                        name='test'
                                        className='d-flex flex-row align-items-end align-self-center gap-2 mb-0 inter-regular text-dark-brown user-select-none mb-2 laurel-checkbox lg'
                                        label={t('login.card.remember')}
                                    />
                                    <Button type='submit' className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3'>{t('login.card.button')}</Button>
                                </Form>
                                {errors.login &&
                                    <Alert variant='danger' className='text-center'>{t('login.errors.incorrectCredentials')}</Alert>

                                }
                                <div className='d-flex flex-column w-100 mb-4'>
                                    <p className='mb-0 inter-regular text-coal opacity-50 fs-5 mb-4 text-center'>{t('login.card.socialText')}</p>
                                    <LoginSocialGoogle
                                        isOnlyGetToken
                                        client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                                        scope="profile email"
                                        onResolve={({ provider, data }) => {
                                            socialLogin(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.error(err)
                                        }}
                                        className='mb-2 w-100'
                                    >
                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                            <div className='google-logo logo me-2'></div>
                                            <span className="small radius opacity-75">{t('modals.subscribe.social.google')}</span>
                                        </Button>
                                    </LoginSocialGoogle>
                                    <LoginSocialFacebook
                                        appId={'827246235309311'}
                                        fieldsProfile={
                                            'id,first_name,last_name,middle_name,name,short_name,email'
                                        }
                                        onResolve={({ provider, data }) => {
                                            socialLogin(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.error(err)
                                        }}
                                        className='mb-2 w-100'
                                    >
                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                            <div className='facebook-logo me-2'></div>
                                            <span className="tt-norms-semi-bolder small radius opacity-75">{t('modals.subscribe.social.facebook')}</span>
                                        </Button>
                                    </LoginSocialFacebook>
                                    <LoginSocialApple
                                        client_id={'com.proadata.traveltozero.signin'}
                                        scope={'name email'}
                                        onResolve={({ provider, data }) => {
                                            socialLogin(provider, data)
                                        }}
                                        redirect_uri={`${window.origin}/${UserDataService.getLanguagePreference()}/login`}
                                        onReject={(err) => {
                                            console.error(err)
                                        }}
                                        className='w-100'
                                    >
                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                            <div className='apple-logo me-2'></div>
                                            <span className="small radius opacity-75">{t('modals.subscribe.social.apple')}</span>
                                        </Button>
                                    </LoginSocialApple>
                                </div>
                                <p className='inter-medium text-brown text-center'>{t('login.rightCard.register.title')}</p>
                                <Link href={t('pages.register')} className='inter-bold text-dark-brown text-center'>{t('login.card.register.link')}</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main >
        </>
    )
}

export default NewLogin