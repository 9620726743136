import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Nav, Navbar, NavDropdown, Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Link from "./Link";

import NavArticle from "./nav-article/NavArticle";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import AuthService from "../services/auth.service";

import ArticleService from "../services/articles.service";
import UserDataService from "../services/userData.service";
import NewContentCard from "./cards/NewContentCard";
import MobileTopbar from "./MobileTopbar";
import { NavbarContext } from "../Contexts";

const Topbar = () => {

    library.add(fas);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();

    const { state: { categories, languages, detailSlugs, user }, state, update } = useContext(AppContext);
    const { navbarState: { navbarHide, bottomNavHide }, navbarState, navbarUpdate } = useContext(NavbarContext)

    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const [articles, setArticles] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [canSearch, setCanSearch] = useState(false);
    const [navExpanded, setNavExpanded] = useState(false);
    const [dropShow, setDropShow] = useState(false);
    let dropdownHandle = {
        guide: false
    }

    const changeLanguage = (lang) => {
        const routes = t("pages", { returnObjects: true });
        const newRoutes = t("pages", { returnObjects: true, lng: lang });
        const currentPath = location.pathname.split("/");

        let newPath = `${window.origin}/${lang}`;
        let urlParams = '?'

        if (currentPath[2]) {
            const section = Object.keys(routes).find(key => routes[key] === currentPath[2]);

            newPath += `/${newRoutes[section]}`;

            if (section === "guide" && currentPath[3]) {
                categories.forEach(category => {
                    if (category.slugs[i18n.language] === currentPath[3]) {
                        newPath += `/${category.slugs[lang]}`;
                    }
                });

                if (currentPath[4]) {
                    newPath += `/${detailSlugs[lang]}`;
                }
            }

            if ((section === "explore" || section === "miniGuide") && currentPath[3]) {
                categories.forEach(category => {
                    if (category.slugs[i18n.language] === currentPath[3]) {
                        newPath += `/${category.slugs[lang]}`;
                    }
                });

                if (currentPath[4]) {
                    newPath += `/${detailSlugs?.[lang]}`;
                }
            }

            if (section === "villa" && currentPath[3]) {

                const page = Object.keys(routes).find(key => routes[key] === currentPath[3])

                newPath += `/${newRoutes[page]}`;

                if (currentPath[4]) {
                    newPath += `/${currentPath[4]}`;
                }

                if (searchParams?.size > 0) {

                    searchParams.forEach((value, key) => {
                        urlParams += `${key}=${value}`
                    })

                    newPath += urlParams
                }

            }
        }

        return newPath;
    }

    const getArticles = async () => {
        const articles = await ArticleService.getArticles({
            length: 6
        });
        setArticles(articles.content)
    }

    const logout = () => {
        AuthService.logout();
        update({ ...state, user: null })
    }

    const searchArticles = (responsive) => {

        if (responsive) {
            setNavExpanded(false)
        }

        let scapedString = textSearch.replace("/", "%2F");
        scapedString = scapedString.replace("#", "%23");

        navigate(`/${i18n.language}/${t('pages.guide')}/${t("pages.search")}/${scapedString}`);
    }

    useEffect(() => {
        getArticles();
    }, [])

    useEffect(() => {
        let search = textSearch.trim()

        if (search.length >= 4) {
            setCanSearch(true)
        } else {
            setCanSearch(false)
        }

    }, [textSearch])

    useEffect(() => {
        setNavExpanded(false)
        setDropShow(false)
    }, [location])

    return (
        <Navbar as={"header"} collapseOnSelect expand="lg" fixed="top" variant="light" className={`border-bottom border-1 border-white bg-laurel ${!navbarHide ? "show" : "hide"}`} expanded={navExpanded}>
            <Container as={"nav"} className="nav-responsive-fluid align-items-center d-none d-lg-flex" fluid={true}>
                <Nav as={"div"} className="d-flex flex-grow-1 flex-basis-0" >
                    <ul className="d-none d-lg-flex flex-row gap-0 gap-xl-3 align-items-end">
                        <li className={`position-static ps-0 text-white ${dropShow ? "show" : ""} ${location.pathname.split('/')[2] === t("pages.guide") ? "inter-medium text-coal" : "inter-light"} nav-item dropdown`}
                            onMouseLeave={() => {
                                dropdownHandle.guide = setTimeout(() => {
                                    setDropShow(false)
                                }, 85);
                            }}
                            onMouseEnter={() => {
                                clearTimeout(dropdownHandle.guide)
                                setDropShow(true)
                            }}
                        >
                            <Link href={t('pages.guide')} className={"ps-0 dropdown-toggle nav-link lh-sm letter-spacing-1"}>{t("navbar.guide").toUpperCase()}</Link>
                            <div className={`dropdown-menu guide-dropdown ${!dropShow ? "d-none" : "d-block"}`.trim()}>
                                <Row className="mx-0 pt-3 pb-4">
                                    <Col lg={2} className="text-start ps-lg-2 ps-xl-2 ps-xxl-2" id="nav-tags">
                                        {categories.map((category, index) =>
                                            <div key={index} className={(params?.category === category.slugs[i18n.language] && location.pathname.includes(t('pages.guide'))) ? "inter-bold" : "inter-regular"} onClick={() => setDropShow(false)}>

                                                {location.pathname.includes(t('pages.guide')) ? (
                                                    <Link href={`${t("pages.guide")}/${category.slugs[i18n.language]}`} className="letter-spacing-1 dropdown-item">{category.name}</Link>
                                                ) : (
                                                    <span className="inter-regular dropdown-item cursor-pointer" onClick={() => navigate(`${t("pages.guide")}/${category.slugs[i18n.language]}`)}>{category.name}</span>
                                                )}
                                            </div>
                                        )}
                                    </Col>
                                    <Col lg={10}>
                                        <Row className="flex-nowrap mx-0 nav-articles">
                                            {articles?.map((article, i) =>
                                                <Col key={i} xs={2}>
                                                    <NewContentCard href={`${t("pages.guide")}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className={"text-coal d-flex flex-grow-1"} theme={"brown"} image={article?.listImageUrl} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </li>
                        <Nav.Item as="li" onClick={() => setDropShow(false)}>
                            <Link href={`${t("pages.explore")}`} className={`text-uppercase nav-link lh-sm letter-spacing-1 ${location.pathname.split('/')[2] === t("pages.explore") ? "inter-medium" : "inter-light"}`}>{t("navbar.explore")}</Link>
                        </Nav.Item>
                        <Nav.Item as="li" onClick={() => setDropShow(false)}>
                            <Link href={`${t("pages.miniGuide")}`} className={`text-uppercase nav-link lh-sm letter-spacing-1 ${location.pathname.split('/')[2] === t("pages.miniGuide") ? "inter-medium" : "inter-light"}`}>{t("navbar.must")}</Link>
                        </Nav.Item>
                        <Nav.Item as="li" onClick={() => setDropShow(false)}>
                            <Link href={`${t("pages.villa")}`} className={`text-uppercase nav-link lh-sm letter-spacing-1  ${location.pathname.split('/')[2] === t("pages.villa") ? "tt-norms-bolder text-black" : "tt-norms"}`}>{t("navbar.villas")}</Link>
                        </Nav.Item>
                    </ul>
                </Nav>
                <Navbar.Brand className="d-none d-lg-block mx-0" onClick={() => setDropShow(false)}>
                    <Link>
                        <div className="d-flex flex-row justify-content-center justify-content-md-start justify-content-lg-start" >
                            <img src="/img/svg/new-logo.svg" alt="" width={220} />
                        </div>
                    </Link>
                </Navbar.Brand>
                <Nav className="d-flex flex-grow-1 flex-basis-0 justify-content-end mt-2 mt-lg-0">
                    {!showSearch && <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="me-2 d-none d-lg-block cursor-pointer text-white" width={16} height={16} onClick={() => setShowSearch(true)} />}
                    {showSearch &&
                        <Form onSubmit={(e) => {
                            e.preventDefault()
                            canSearch && searchArticles();
                        }}>
                            <InputGroup className="input-group-sm searchInput d-none d-lg-flex bg-transparent">
                                <Form.Control placeholder={t("guide.search.placeholder")} className="rounded-start inter-regular text-coal" value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
                                <InputGroup.Text className="rounded-end"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className={canSearch ? "search-icon-active" : "search-icon-disabled"} width={16} height={16} onClick={() => { canSearch && searchArticles() }} /></InputGroup.Text>
                            </InputGroup>
                        </Form>
                    }
                    <NavDropdown title={i18n.language.toUpperCase()} id="collasible-nav-dropdown" className="inter-light d-none d-lg-flex align-items-center lh-sm letter-spacing-1">
                        {languages.map(language =>
                            <NavDropdown.Item key={language} className="inter-light" href={changeLanguage(language)}>
                                {language.toUpperCase()}
                            </NavDropdown.Item>)
                        }
                    </NavDropdown>
                    <Form className="mb-2" onSubmit={(e) => {
                        e.preventDefault()
                        canSearch && searchArticles(true);
                    }}>
                        <InputGroup className="input-group searchInput d-flex d-lg-none">
                            <Form.Control placeholder="Buscar..." className="rounded-start" value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
                            <InputGroup.Text className="rounded-end"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className={canSearch ? "search-icon-active" : "search-icon-disabled"} width={16} height={16} onClick={(e) => canSearch && searchArticles(true)} /></InputGroup.Text>
                        </InputGroup>
                    </Form>
                    <NavDropdown id="guideDropdown" title={t("navbar.guide").toUpperCase()} className="d-lg-none pt-2 pb-2 py-lg-0">
                        <span className={Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.guide") ? "tt-norms-bolder py-2 dropdown-item" : "tt-norms text-capitalize py-2 dropdown-item"} onClick={() => setNavExpanded(false)}>
                            <Link href={`${t("pages.guide")}`}>{t("navbar.tag.guide")}</Link>
                        </span>
                        {categories.map((category, index) =>
                            <span key={index} className={params?.category === category.slugs[i18n.language] ? "tt-norms-bolder py-2 dropdown-item" : "tt-norms py-2 dropdown-item"}>
                                <Link href={`${t("pages.guide")}/${category.slugs[i18n.language]}`}>{category.name}</Link>
                            </span>
                        )}
                    </NavDropdown>
                    <Nav.Item className={`text-uppercase pe-0 py-2 d-lg-none ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.miniGuide") && "tt-norms-bold text-black"}`} onClick={() => setNavExpanded(false)}>
                        <Link href={`${t("pages.miniGuide")}`} className={`${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.miniGuide") ? "tt-norms-bold text-black" : "nav-link"}`}>{t("navbar.must")}</Link>
                    </Nav.Item>
                    <Nav.Item className={`text-uppercase pe-0 py-2 d-lg-none ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.explore") && "tt-norms-bold text-black"}`} onClick={() => setNavExpanded(false)}>
                        <Link href={`${t("pages.explore")}`} className={`${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.explore") ? "tt-norms-bold text-black" : "nav-link"}`}>{t("navbar.explore")}</Link>
                    </Nav.Item>
                    <Nav.Item className={`text-uppercase pe-0 py-2 d-lg-none ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.villa") && "tt-norms-bold text-black"}`} onClick={() => setNavExpanded(false)}>
                        <Link href={`${t("pages.villa")}`} className={`text-uppercase nav-link ${location.pathname.split('/')[2] === t("pages.villa") ? "tt-norms-bolder text-black" : "tt-norms"}`}>{t("navbar.villas")}</Link>
                    </Nav.Item>
                    <Nav.Item className={`text-uppercase pe-0 py-2 d-lg-none ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.explore") && "tt-norms-bold text-black"}`} onClick={() => setNavExpanded(false)}>
                        <Link href={user ? `${t('pages.profile')}/${t('pages.favourites')}` : `${t("pages.login")}`} className={`d-flex flex-row justify-content-between ${Object.keys(params).length === 0 && location.pathname.split('/')[3] === t("pages.favourites") ? "tt-norms-bold text-black" : "nav-link"}`}>
                            <span>{t("navbar.favorite")}</span>
                            <span className="bg-primary tt-norms-semi-bolder radius text-black px-2">NEW!</span>
                        </Link>
                    </Nav.Item>
                    <Nav.Item className={`text-uppercase pe-0 py-2 d-lg-none ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.profile") && "tt-norms-bold text-black"}`} onClick={() => setNavExpanded(false)}>
                        <Link href={user ? `${t("pages.profile")}` : `${t("pages.login")}`} className={`${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.profile") && !location.pathname.split('/')?.[3] ? "tt-norms-bold text-black" : "nav-link"}`}>{t("navbar.profile.profile")}</Link>
                    </Nav.Item>
                    <div className="d-flex flex-row align-items-start align-items-lg-center pt-2 pb-2 py-lg-0">
                        {!user ? (
                            <div onClick={() => {
                                setNavExpanded(false)
                                setDropShow(false)
                            }}>
                                <span className={`text-uppercase nav-center-icon pe-0 cursor-pointer lh-sm letter-spacing-1 ${Object.keys(params).length === 0 && location.pathname.split('/')[2] === t("pages.login") ? "inter-medium text-black nav-link" : "inter-light nav-link"}`} onClick={() => navigate(`${t('pages.login')}`)}>Login</span>
                            </div>
                        ) : (
                            <NavDropdown id="user-nav-dropdown" className="d-block pe-0" title={
                                <div className="inter-light radius text-white" id="userNav">
                                    {user?.firstName || user?.email.split('@')?.[0] || 'User'}
                                    <FontAwesomeIcon icon={"chevron-down"} className="text-white ms-1" width={15} height={15} />
                                </div>}>
                                <span className="tt-norms dropdown-item cursor-pointer d-none d-lg-block">
                                    <Link href={`${t('pages.profile')}`} className={"text-coal"}>{t('navbar.profile.profile')}</Link>
                                </span>
                                <Link href={`${t('pages.profile')}/${t('pages.favourites')}`} className={"text-coal d-none d-lg-block"}>
                                    <span className="tt-norms dropdown-item cursor-pointer">{t('navbar.profile.favorite')}</span>
                                </Link>
                                <span className="tt-norms pe-0 dropdown-item cursor-pointer text-coal" onClick={logout}>{t('navbar.profile.exit')}</span>
                            </NavDropdown>
                        )}
                    </div>
                </Nav>
            </Container>
            <MobileTopbar />
        </Navbar>
    );
}


export default Topbar;
