import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormCheck, Image, Row } from 'react-bootstrap'
import { FreeMode, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AppContext } from '../../AppContext'
import { useTranslation } from 'react-i18next'
import NewContentCard from '../../components/cards/NewContentCard'
import ArticleService from '../../services/articles.service'

import 'swiper/css';
import 'swiper/css/navigation';
import { useStickyBox } from 'react-sticky-box'
import Link from '../../components/Link'
import MiniGuideService from '../../services/miniGuide.service'
import WebBanners from '../../components/banners/WebBanners'
import NewContentCardPlaceholder from '../../components/cards/NewContentCardPlaceholder'
import { useParams } from 'react-router'
import SubscribeModal from '../../components/modals/SubscribeModal'
import { NavbarContext } from '../../Contexts'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'

function NewGuide() {

    const { t, i18n } = useTranslation();
    const { state: { categories, user } } = useContext(AppContext)
    const { navbarState: { navbarHide }, navbarUpdate } = useContext(NavbarContext)
    
    const [articles, setArticles] = useState();
    const [reviews, setReviews] = useState();
    const [miniGuides, setMiniGuides] = useState();
    
    const [filterList, setFilterList] = useState([])
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [mobileViewport, setMobileViewport] = useState(window.innerWidth <= 425)
    const stickyRef = useStickyBox({ offsetTop: !mobileViewport ? 59 : navbarHide ? 0 : 55 })

    const cancelRequest = useRef()

    const getArticles = async () => {
        try {
            const data = await ArticleService.getArticles({ length: 3, type: 1, categoryIds: filterList?.toString() })
            setArticles(data?.content)
        } catch (error) {
            console.error(error);
        }
    }

    const getReviews = async () => {
        try {
            const data = await ArticleService.getArticles({ length: 3, type: 2, categoryIds: filterList?.toString() })
            setReviews(data?.content)
        } catch (error) {
            console.error(error);
        }
    }

    const getMiniGuides = async () => {
        try {
            const data = await MiniGuideService.getMiniGuide({ length: 3, categoryIds: filterList?.toString() })
            setMiniGuides(data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilter = (type) => {
        const localFilterList = [...filterList];
        const filterIndex = localFilterList.indexOf(type);

        if (filterIndex >= 0) {
            localFilterList.splice(filterIndex, 1)
            setFilterList(localFilterList)
        } else {
            setFilterList([
                ...localFilterList,
                type
            ])
        }
    }

    useEffect(() => {
        if (articles) {
            cancelRequest.current = setTimeout(() => {
                setArticles();
                setReviews();
                setMiniGuides();
            }, 1000);
        }

        return () => {
            clearTimeout(cancelRequest.current)
        }
    }, [filterList])

    useEffect(() => {
        if (filterList?.length > 0 && !articles && !reviews) {
            getArticles();
            getReviews()
            getMiniGuides()
        } else if (filterList?.length === 0 && !articles && !reviews) {
            getArticles();
            getReviews()
            getMiniGuides()
        }
    }, [articles, reviews])

	useEffect(() => {
        let waitResize;		
		
        const checkResize = () => {
			setMobileViewport(window.innerWidth <= 425)
        }

        window.addEventListener('resize', () => {			
            clearTimeout(waitResize);
            waitResize = setTimeout(checkResize, 100)
        })

        return () => window.removeEventListener('resize', checkResize)
	}, [])


    return (
        <>
            <SeoHelmet title={t('seo.guide.title')} metaTitle={t('seo.guide.metaTitle')} metaDescription={t('seo.guide.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <main>
                <section>
                    <Row className='guide-hero g-0 flex-column-reverse flex-lg-row'>
                        <Col lg={4} className='d-flex flex-column justify-content-around align-items offset-lg-1 p-4 p-lg-0'>
                            <div className='d-flex flex-column justify-content-center gap-3 gap-lg-4 mb-4 mb-lg-0'>
                                <h1 className='fs-title fitzgerald-regular text-dark-green mb-0'>{t('guide.title1')}</h1>
                                <p className='inter-medium text-uppercase letter-spacing-1 fs-5 mb-0 text-dark-green'>{t('guide.title2')}</p>
                                <p className='inter-regular fs-6 fs-lg-5 text-dark-green'>{t('guide.description')}</p>
                            </div>
                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                <span className='inter-regular fs-6 fs-lg-5 text-dark-green'>{t('guide.subscribe.title')}</span>
                                <Button variant='none' className='btn-dark-green-outline inter-regular px-4 px-lg-5' onClick={handleShow}>{t('guide.subscribe.button')}</Button>
                            </div>
                        </Col>
                        <Col lg={6} className='offset-lg-1'>
                            <Image src="/img/guide/hero-guide.webp" className='fit-cover' />
                        </Col>
                    </Row>
                </section>
                <section className='bg-laurel guide-category-filter' ref={stickyRef}>
                    <div className='px-0 px-lg-4 container-lg'>
                        <Swiper
                            navigation
                            breakpoints={{
                                768: {
                                    slidesOffsetBefore: 24,
                                    slidesOffsetAfter: 24
                                }
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={32}
                            slidesOffsetBefore={window.innerWidth < 768 ? 24 : 0}
                            slidesOffsetAfter={window.innerWidth < 768 ? 24 : 0}
                            className='w-100 py-3'
                            shortSwipes
                            longSwipes
                            freeMode
                            modules={[FreeMode, Navigation]}
                        >
                            {categories.map((cat, i) =>
                                <SwiperSlide key={i}>
                                    <Form.Check
                                        key={i}
                                        id={cat?.slugs?.[i18n?.language] || cat?.name}
                                        className='tt-norms d-flex flex-row align-items-center filter-item justify-content-center mb-0 inter-medium text-white letter-spacing-1'
                                        label={cat.name}
                                        onChange={() => handleFilter(cat?.id)}
                                    // checked={filterCategory.includes(cat?.id) ? true : false}
                                    // onChange={handleItemClick(cat?.id)}
                                    />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </section>
                {(!reviews || reviews?.length > 0) && <section className='py-5 border-bottom border-coal-semi-transparent'>
                    <div className='d-flex flex-column container-lg '>
                        <h2 className='inter-semi-bold fs-2 text-dark-green letter-spacing-2 text-uppercase mb-3'>{t('guide.reviews.title')}</h2>
                        <h3 className='inter-regular fs-4 text-dark-green mb-4 mb-lg-5'>{t('guide.reviews.subtitle')}</h3>
                        <Row className='gy-5 gx-4 mb-5'>
                            {!reviews && (
                                [1, 2, 3].map((i) => (
                                    <Col lg={4} key={i}>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                ))
                            )}
                            {reviews?.length > 0 && (
                                reviews?.map((article, i) => (
                                    <Col lg={4} key={i}>
                                        <NewContentCard theme={"laurel"} image={article?.listImageUrl} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
                                    </Col>
                                ))
                            )}
                        </Row>
                    </div>
                </section>}
                {(!articles || articles?.length > 0) && <section className='py-5 border-bottom border-coal-semi-transparent'>
                    <div className='d-flex flex-column container-lg '>
                        <h2 className='inter-semi-bold fs-2 text-dark-green letter-spacing-2 text-uppercase mb-3'>{t('guide.article.title')}</h2>
                        <h3 className='inter-regular fs-4 text-dark-green mb-4 mb-lg-5'>{t('guide.article.subtitle')}</h3>
                        <Row className='gy-5 gx-4 mb-5'>
                            {!articles && (
                                [1, 2, 3].map((i) => (
                                    <Col lg={4} key={i}>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                ))
                            )}
                            {articles?.length > 0 && (
                                articles?.map((article, i) => (
                                    <Col lg={4} key={i}>
                                        <NewContentCard theme={"laurel"} image={article?.listImageUrl} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
                                    </Col>
                                ))
                            )}
                        </Row>
                    </div>
                </section>}
                {(!miniGuides || miniGuides?.length > 0) && <section className='bg-beige py-5 border-bottom border-coal-semi-transparent'>
                    <div className='d-flex flex-column container-lg '>
                        <h2 className='inter-semi-bold fs-2 text-brown letter-spacing-2 text-uppercase mb-3'>It's-a-must</h2>
                        <h3 className='inter-regular fs-4 text-dark-green mb-4 mb-lg-5 text-brown'>{t('guide.must.subtitle')}</h3>
                        <Row className='gy-5 gx-4 mb-5'>
                            {!miniGuides && (
                                [1, 2, 3].map((i) => (
                                    <Col lg={4} key={i}>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                ))
                            )}
                            {miniGuides?.map((miniGuide, i) => (
                                <Col lg={4} key={i}>
                                    <NewContentCard theme={"brown"} image={miniGuide?.mainPicture} category={miniGuide?.category} title={`${miniGuide?.title}`.trim()} slug={`${t('pages.miniGuide')}/${miniGuide?.categorySlug}/${miniGuide?.slug?.[i18n.language]}`} id={miniGuide?.id} type={"MINIGUIDE"} />
                                </Col>
                            ))}
                        </Row>
                        <Link href={t('pages.miniGuide')} className={"align-self-center"}>
                            <Button className='btn-brown-outline letter-spacing-1 inter-regular py-3 px-5 align-self-center'>{t('general.components.seeAllM')}</Button>
                        </Link>
                    </div>
                </section>}
                <section className='px-0 px-lg-4 container-lg py-5'>
                    <WebBanners />
                </section>
                <section className='container-lg pb-5'>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={12} lg={12} className="instagram-widget">
                            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-end mb-3'>
                                <span className='inter-medium fs-5 mb-1 text-dark-green'>{t('miniGuide.follow')} @traveltozero</span>
                            </div>
                            <div>
                                <div className="elfsight-app-34b7b62a-350f-4b31-8fbc-794e7795858f"></div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </main>
            <SubscribeModal show={show} close={handleClose} />
        </>
    )
}

export default NewGuide