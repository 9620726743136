import { Alert, Button, Col, Container, Form, Image, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { LoginSocialApple, LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login"
import TokenService from "../../services/token.service"
import UserService from "../../services/user.service"
import AuthService from "../../services/auth.service"
import { FavouritesContext } from "../../Contexts"
import { useContext, useState } from "react"
import { AppContext } from "../../AppContext"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import UserDataService from "../../services/userData.service"
import RegisterService from "../../services/register.service"
import Link from "../Link"

const ArticleLimit = ({ article }) => {

    const { register } = useForm({
        mode: "all",
        defaultValues: {}
    })

    const params = useSearchParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { state: { shareToken, user }, state, update } = useContext(AppContext)
    const { favouriteState: { favoriteRedirect } } = useContext(FavouritesContext);

    const [email, setEmail] = useState("pedroproadatatest@gmail.com");
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [confirmEmail, setConfirmEmail] = useState(false);
    const [errors, setErrors] = useState({
        password: false,
        email: false,
        server: false
    });

    const createAccount = async () => {
        setErrors({
            password: false,
            email: false,
            server: false
        })

        if (password === confirmPassword) {
            try {
                await RegisterService.sendEmail({
                    email: email,
                    password: password,
                    validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
                })
                setConfirmEmail(true)
            } catch ({ response }) {
                if (response?.data?.errorCode) {
                    switch (response.data.errorCode) {
                        case 13002:
                            setErrors({ ...errors, email: true });
                            break;
                        default:
                            setErrors({ ...errors, server: true });
                    }
                }
            }
        }
    }

    const reSendMail = async () => {
        try {
            await RegisterService.reSendEmail({
                email,
                validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
            })
        } catch (error) {
            console.error(error);
        }
    }

    const socialLogin = async (provider, data) => {
        setErrors({
            password: false,
            email: false,
            server: false
        })

        try {
            const token = await AuthService.socialLoginToken({
                provider,
                token: data.access_token || data.accessToken || data.authorization.id_token,
                firstName: data.user !== undefined ? data.user.name.firstName : null,
                lastName: data.user !== undefined ? data.user.name.lastName : null
            })
            await TokenService.setUser(token)
            const user = await UserService.userInfo();
            const userShareToken = TokenService.getShareFolderToken();

            update({
                ...state,
                user
            });
            await UserDataService.updateUserInLogin();

            if (shareToken && !userShareToken) {
                let encodedParam = encodeURIComponent(shareToken);
                await TokenService.setShareFolderToken(encodedParam);
            }

            if (i18n.language !== UserDataService.getLanguagePreference()) {
                window.location.href = `${window.origin}/${UserDataService.getLanguagePreference()}/${shareToken ? t('pages.profile') : ""}`;
            } else {
                let redirectUrl = `/${i18n.language}/${t('pages.guide')}/${location.pathname.split("/")?.[3]}/${article?.slugs?.[i18n.language]}`
                navigate(redirectUrl)
            }
        } catch (error) {
            setErrors({ ...errors, server: true });
        }
    }

    return (
        <Container>
            <Row className="justify-content-center h-100">
                <Col lg={4}>
                    <Row className="justify-content-center" id="form-container">
                        {!confirmEmail ? (
                            <Col className="d-flex flex-column justify-content-center py-4">
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={12}>
                                        <p className="fs-2 fitzgerald-regular text-center">{t('articles.limiter.title')}</p>
                                    </Col>
                                </Row>
                                <div className="d-flex flex-column align-items-center gap-2 login-container">
                                    <Form className="d-flex flex-column justify-content-center gap-2 login-content" onSubmit={(e) => {
                                        e.preventDefault()
                                        createAccount()
                                    }}>
                                        <Form.Group>
                                            <Form.Control type="email" className='inter-regular fs-6 bg-secondary' size='lg' placeholder={t('modals.subscribe.form.email')} onChange={(e) => { setEmail(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" className='inter-regular fs-6 bg-secondary' size='lg' placeholder={t('modals.subscribe.form.password')} onChange={(e) => { setPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Control type="password" className='inter-regular fs-6 bg-secondary' size='lg' placeholder={t('modals.subscribe.form.confirm')} onChange={(e) => { setConfirmPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group className="d-flex justify-content-center align-self-start gap-2 justify-content-start" controlId="comunication">
                                            <Form.Check id='comunication' className='d-flex flex-row align-items-end align-self-center user-select-none mb-1 laurel-checkbox lg' type="checkbox" {...register('comunication')} />
                                            <label for={"comunication"} className='inter-regular text-dark-brown user-select-none align-self-center small'>{t('register.card.generalComunications')}</label>
                                        </Form.Group>
                                        <Form.Group className="d-flex justify-content-center align-self-start gap-2 justify-content-start mb-2" controlId="offers">
                                            <Form.Check id='offers' className='d-flex flex-row align-items-end align-self-center gap-2 inter-regular text-dark-brown user-select-none mb-1 laurel-checkbox lg' type="checkbox" {...register('offers')} />
                                            <label for={"offers"} className='inter-regular text-dark-brown user-select-none align-self-center small'>{t('register.card.customOffers')}</label>
                                        </Form.Group>
                                        <p className="mb-0 inter-regular text-coal opacity-75 mb-3 small">{t('register.card.textInformation1')} <Link href={`${t('pages.privacy')}`} target={"_blank"} className="text-black text-decoration-underline">{t('register.card.textInformation2')}</Link></p>
                                        <Button type='submit' className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3 mb-3'>{t('register.card.button')}</Button>
                                        <Alert variant={"danger"} show={errors.password} dismissible onClose={() => setErrors({ ...errors, password: false })}>
                                            {t("register.errors.password")}
                                        </Alert>
                                        <Alert variant={"danger"} show={errors.email} dismissible onClose={() => setErrors({ ...errors, email: false })}>
                                            {t("register.errors.email")}
                                        </Alert>
                                        <Alert variant={"danger"} show={errors.server} dismissible onClose={() => setErrors({ ...errors, server: false })}>
                                            {t("register.errors.server")}
                                        </Alert>
                                        <div className='d-flex flex-column w-100 mb-4 mb-lg-0'>
                                            <p className='mb-0 inter-regular text-coal opacity-50 fs-5 mb-4 text-center'>{t('login.card.socialText')}</p>
                                            <LoginSocialGoogle
                                                isOnlyGetToken
                                                client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                                                scope="profile email"
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2 w-100'
                                            >
                                                <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center bg-secondary'>
                                                    <div className='google-logo logo me-2'></div>
                                                    <span className="small radius opacity-75">{t('modals.subscribe.social.google')}</span>
                                                </Button>
                                            </LoginSocialGoogle>
                                            <LoginSocialFacebook
                                                appId={'827246235309311'}
                                                fieldsProfile={
                                                    'id,first_name,last_name,middle_name,name,short_name,email'
                                                }
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2 w-100'
                                            >
                                                <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center bg-secondary'>
                                                    <div className='facebook-logo me-2'></div>
                                                    <span className="tt-norms-semi-bolder small radius opacity-75">{t('modals.subscribe.social.facebook')}</span>
                                                </Button>
                                            </LoginSocialFacebook>
                                            <LoginSocialApple
                                                client_id={'com.proadata.traveltozero.signin'}
                                                scope={'name email'}
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                redirect_uri={`${window.origin}/${UserDataService.getLanguagePreference()}/login`}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2 w-100'
                                            >
                                                <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center bg-secondary'>
                                                    <div className='apple-logo me-2'></div>
                                                    <span className="small radius opacity-75">{t('modals.subscribe.social.apple')}</span>
                                                </Button>
                                            </LoginSocialApple>
                                            <Link href={`${t('pages.login')}`} onClick={() => update({ ...state, returnRedirect: `/${i18n.language}/${t('pages.guide')}/${location.pathname.split("/")?.[3]}/${article?.slugs?.[i18n.language]}` })}>
                                                <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center bg-secondary'>
                                                    <Image width={22} src={'/img/icons/wheel-icon.svg'} className="me-2" />
                                                    <span className="small radius opacity-75">{t('modals.subscribe.social.tzo')}</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        ) : (
                            <Col lg={6} className="py-4">
                                <p className='fitzgerald-regular fs-1 mb-4 text-center'>{t('register.card.confirm.title')}</p>
                                <p className="inter-regular mb-2 text-center">{t('register.card.confirm.text1')} <b>{email}</b> {t('register.card.confirm.text2')}</p>
                                <p className="inter-regular mb-0 align-self-start text-center">{t('register.card.confirm.text3')}</p>
                                <p className="inter-medium text-center mt-5 mb-4">{t('register.card.confirm.text4')}</p>
                                <div className="text-center">
                                    <Button className="btn-dark-brown-outline inter-medium px-5 radius" variant="primary" onClick={() => reSendMail()}>
                                        {t('register.card.confirm.button')}
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ArticleLimit